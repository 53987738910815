import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  link: {
    color: theme.palette.primary.dark,
    "&:link": {
      textDecoration: "none"
    },
    "&:visited": {
      textDecoration: "none"
    },
    "&:hover": {
      textDecoration: "none"
    },
    "&:active": {
      textDecoration: "none"
    },
  }
});


class AttributionTag extends React.Component {
  render() {
    const { data, classes } = this.props
    return (
      <Typography color="inherit">
        {data.intro} <a className={classes.link} href={data.url} target="_blank" rel="noopener noreferrer">{data.name}</a>
      </Typography>
      )
  }
}

AttributionTag.propTypes = {
  data: PropTypes.object,
}

export default withStyles(styles)(AttributionTag)
