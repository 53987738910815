import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import Markdown from 'react-markdown'

import { withStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import LogoIcon from '../components/icons/LogoIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = theme => ({
  hero: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
  title: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamilySecond,
    // textTransform: "uppercase",
    color: theme.palette.primary.dark,
  },
  textContent: {
    "z-index": 10,
  },
  heroContent: {
    // ...theme.typography.button,
    color: theme.palette.hero.main,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing.unit * 5,
    "min-height": "calc( 100vh - 114px )",
    textAlign: "center",
  },
  heroSub: {
    textAlign: "center",
    height: 50,
    backgroundColor: theme.palette.common.black,
    opacity: 0.97,
  },
  iconButton: {
    "z-index": 10,
  },
  icon: {
    backgroundColor: theme.palette.hero.light,
    width: 300,
    borderRadius: "50%",
    border: "solid 3px",
    color: theme.palette.primary.dark,
    margin: 10,
    "& #top, #bottom": {
      fill: theme.palette.common.black
    },
    "& #dart": {
      fill: theme.palette.primary.dark,
    },
  },
});

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.handleScrollClick = this.handleScrollClick.bind(this)
    this.handleOpenClick = this.handleOpenClick.bind(this)
  }

  handleScrollClick(event) {
    event.preventDefault()
    if (typeof this.props.onScrollClick === 'function') {
      this.props.onScrollClick(event)
    }
  }

  handleOpenClick(event) {
    event.preventDefault()
    if (typeof this.props.onOpenClick === 'function') {
      this.props.onOpenClick(event)
    }
  }

  render() {
    const { classes, data } = this.props
    return (
      <Grid className={classes.hero} container justify="center" spacing={0}>
        <Grid className={classes.heroContent} item xs={12}>
          <Grid container spacing={32}>
            <Grid item xs={12}>
              <IconButton className={classes.iconButton} onClick={this.handleOpenClick} color="primary">
                <LogoIcon className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item xs={12}>

              <div className={classes.textContent}>
                <Typography className={classes.title} variant="h2" color="inherit" gutterBottom>{data.header.title}</Typography>
                <Typography variant="h4" color="inherit" component={Markdown} source={data.header.introduction} gutterBottom />
                {!data.header.learn_more_hidden &&
                  <Button color="inherit" component={Link} to={data.header.learn_more_url}>{data.header.learn_more}</Button>
                }
              </div>

            </Grid>
          </Grid>
        </Grid>
        <Grid item id="hero-sub" className={classes.heroSub} xs={12}>

          <IconButton color="primary" onClick={this.handleScrollClick}>
            <SvgIcon color="inherit" viewBox="0 0 512 512" aria-label="Down">
              <path d="M256,298.3L256,298.3L256,298.3l174.2-167.2c4.3-4.2,11.4-4.1,15.8,0.2l30.6,29.9c4.4,4.3,4.5,11.3,0.2,15.5L264.1,380.9c-2.2,2.2-5.2,3.2-8.1,3c-3,0.1-5.9-0.9-8.1-3L35.2,176.7c-4.3-4.2-4.2-11.2,0.2-15.5L66,131.3c4.4-4.3,11.5-4.4,15.8-0.2L256,298.3z" />
            </SvgIcon>
          </IconButton>

        </Grid>

      </Grid>
    )
  }
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
  onScrollClick: PropTypes.func
}

export default withStyles(styles)(Hero)
