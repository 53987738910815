import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Fade from "@material-ui/core/Fade"
import PageHeader from './PageHeader'

const styles = theme => ({
  root: {
  },
});

class PageHeaderWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loaded: false }
    this.handleScrollClick = this.handleScrollClick.bind(this)
  }

  handleScrollClick(event) {
    event.preventDefault()
    if (this.props.onScrollClick) {
      const { handler, id } = this.props.onScrollClick
      const scrollID = id || "header-sub"
      handler(scrollID)
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true,
      open: false,
    })
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Fade in={this.state.loaded} timeout={{ enter: 600 }}>
          <PageHeader className={classes.root} data={this.props.headerData} showScrollButton={this.props.onScrollClick} />
        </Fade>
      </React.Fragment>
    )
  }
}

PageHeaderWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  onScrollClick: PropTypes.object,
}

export default withStyles(styles)(PageHeaderWrapper)
