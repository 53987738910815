import React from "react"
import { StaticQuery, graphql } from "gatsby"

import IntroSection from "./IntroSection"

export default props => (
  <StaticQuery
    query={graphql`
      query {
        services {
          content
        }
      }
    `}
    render={data => <IntroSection data={data} {...props} />}
  />
)
