import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20, // Half the value of the grid spacing
    border: `solid 8px ${theme.palette.common.black}`,
    borderRadius: theme.spacing.unit,
  },
  card: {
    minWidth: 345
  },
  action: {
    marginLeft: "auto"
  },
  title: {
    fontFamily: theme.typography.fontFamilySecond,
    fontWeight: 500,
    color: theme.palette.primary.dark
  },
  pageContent: {
    fontFamily: theme.typography.fontFamily,
    "& h1": {
      ...theme.typography.h1,
      color: theme.palette.primary.dark,
    },
    "& h2": {
      ...theme.typography.h2,
      color: theme.palette.primary.dark,
    },
    "& h3": {
      ...theme.typography.h3,
      color: theme.palette.primary.dark,
    },
    "& h4": {
      ...theme.typography.h4,
      color: theme.palette.primary.dark,
    },
    "& h5": {
      ...theme.typography.h5,
      color: theme.palette.primary.dark,
    },
    "& h6": {
      ...theme.typography.h6,
      color: theme.palette.primary.dark,
    },
    "& p": {
      ...theme.typography.body2,
      margin: theme.spacing.unit * 2,
      fontWeight: 500,
      fontSize: 16,
    },
  },
})

class IntroSection extends React.Component {
  render() {
    const { classes, data } = this.props

    return (
      <div className={classes.root}>
        <Grid container spacing={40}>
          <Grid className={classes.header} item xs={12}>
            <Grid container justify="center" spacing={16}>
              <Grid item xs={12}>
                <Markdown className={classes.pageContent} source={data.services.content} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

IntroSection.propTypes = {
  data: PropTypes.object,
}

export default withStyles(styles)(IntroSection)
