import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"

import Navigation from './NavigationQuery';
import PageHeaderWrapper from './PageHeaderWrapper'
import Intro from './IntroSectionQuery';
import Footer from './FooterQuery'
import HeroQuery from './HeroQuery'
import SEO from './SEO'

// import '../assets/scss/style.scss'
const styles = theme => ({
  root: {
    display: "flex",
  },
  mainContent: {
    flexGrow: 1,
  },
  bbg: {
    // borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.common.black,
  },
  introWrapper: {
    flexGrow: 1,
    padding: 20, // Half the value of the grid spacing
    backgroundColor: theme.palette.common.black,
  },
  intro: {
    borderRadius: theme.spacing.unit,
    border: `solid 4px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.hero.main,
  },
  icon: {
    width: 120,
    "& #top, #bottom": {
      fill: theme.palette.secondary.main
    },
    "& #dart": {
      fill: theme.palette.primary.main,
    },
  },
});

const Layout = ({ children, location, onScrollClick, classes, pagedata }) => {
  let content;

  if (location && location.pathname === '/') {
    content = (
      <div className={classes.root}>
        <SEO />
        <Navigation />
        <main className={classes.mainContent}>
          <HeroQuery onScrollClick={onScrollClick} />
          <div className={classes.introWrapper}>
            <Grid container justify="center" spacing={0}>
              <Grid className={classes.intro} item xs={12} lg={9}>
                <Intro />
              </Grid>
            </Grid>
          </div>
          {children}
          <Footer />
        </main>
      </div>
    )
  } else {
    content = (
      <div className={classes.root}>
        <main className={classes.mainContent}>
          <Navigation />
          <PageHeaderWrapper headerData={pagedata} onScrollClick={onScrollClick} />
          {children}
          <Footer />
        </main>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { class: 'utf-8' },
              { name: 'viewport', content: 'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default withStyles(styles)(Layout)
