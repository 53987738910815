import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';

import SocialList from './SocialList'
import Attribution from './Attribution'

import LogoIcon from './icons/LogoIcon'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.primary.light,
  },
  heading: {
    fontWeight: theme.typography.fontWeightMedium * 2,
    fontStyle: "uppercase",
  },
  icon: {
    width: theme.spacing.unit * 10,
    borderRadius: "20%",
    border: "solid 1px",
    margin: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px`,
    "& #top, #bottom": {
      fill: theme.palette.primary.dark
    },
    "& #dart": {
      fill: theme.palette.primary.main,
    },
  },
  item: {
    // borderStyle: "none none none solid",
    // borderWidth: 1,
    // borderColor: theme.palette.primary.dark[500],
  },
  footWrapper: {
    padding: 20,
  },
});

const Footer = (props) => {
  const { classes, id, data } = props
  return (
    <Grid theme="dark" id={id || "footer-grid"} container justify="center" className={classes.root} spacing={0}>
      <Grid className={classes.footWrapper} item xs={12}>
        <Grid container spacing={0}>

          <Grid item xs={12} sm={1} />
          <Grid item xs={12} sm={10}>
            <Grid container justify="space-evenly" spacing={32}>
              <Grid className={classes.item} item>
                <LogoIcon className={classes.icon} />
              </Grid>

              <Grid className={classes.item} item>
                <SocialList data={data} />
              </Grid>

              <Grid className={classes.item} item>
                <div><Button color="inherit" component={Link} to="/terms">Terms and Conditions</Button></div>
                <div><Button color="inherit" component={Link} to="/privacy">Privacy Policy</Button></div>
              </Grid>
              <Grid className={classes.item} item>
                <Attribution data={data} />
              </Grid>
              <Grid className={classes.item} item>
                <div><Typography color="inherit" className={classes.heading} variant="body2" align="center">copyright © {(new Date()).getFullYear()} {data.footer.copyright}</Typography></div>
                <div><Typography color="inherit" variant="body2" align="center">{data.footer.attributionName}</Typography>
                  <Typography color="inherit" className={classes.heading} variant="body2" align="center">Code like you're on <span role="img" aria-label="fire">🔥</span></Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} />
        <Typography color="inherit" variant="body2" align="center">{data.footer.cta.sub_title}</Typography>
      </Grid>
    </Grid>
  )
}

Footer.propTypes = {
  data: PropTypes.object
}

export default withStyles(styles)(Footer)
