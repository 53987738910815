import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Navigation from "./Navigation"

export default props => (
  <StaticQuery
    query={graphql`
      query {
        navigation {
          menu {
            items { type, name, label, path, hidden }
          }
        }
      }
    `}
    render={data => <Navigation data={data} {...props} />}
  />
)
