import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import LogoIcon from '../components/icons/LogoIcon'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = theme => ({
  root: {
    marginTop: 64,
    flexGrow: 1,
    padding: 16, // Half the value of the grid spacing
    backgroundColor: theme.palette.common.black,
  },
  title: {
    marginTop: theme.spacing.unit * 2,
    color: theme.palette.primary.dark,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamilySecond,
    // textTransform: "uppercase",
    // letterSpacing: theme.spacing.unit * 2,
  },
  textContent: {
    "z-index": 10,
    "& p": {
      "& img": {
        maxWidth: "200%",
        "@media (max-width: 1280px)": {
          maxWidth: "100%",
         },
        height: "auto"
      }
    }
  },
  headerContent: {
    color: theme.palette.hero.main,
    textAlign: "center",
    // "min-height": "calc( 100vh - 114px )",
  },
  headerContentSub: {
    minWidth: 400,
    maxWidth: 500,
  },
  headerSub: {
    textAlign: "center",
    backgroundColor: theme.palette.common.black,
    opacity: 0.97,
  },
  iconButton: {
    "z-index": 10,
  },
  icon: {
    width: 120,
    borderRadius: "50%",
    border: "solid 2px",
    margin: 10,
    "& #top, #bottom": {
      fill: theme.palette.primary.dark
    },
    "& #dart": {
      fill: theme.palette.primary.main,
    },
  },
});

class PageHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loaded: false }
    this.handleScrollClick = this.handleScrollClick.bind(this)
  }

  handleScrollClick(event) {
    event.preventDefault()
    if (typeof this.props.onScrollClick === 'function') {
      this.props.onScrollClick(event)
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true,
      open: false,
    })
  }

  render() {
    const { classes, data } = this.props
    const { title, description } = data
    const showScrollButton = !!this.props.onScrollClick
    return (
      <div className={classes.root}>
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <Grid container className={classes.headerContent} justify="space-around">
              <Grid item md={6} lg={10} className={classes.headerContentSub}>
                <div className={classes.textContent}>
                  <Typography className={classes.title} variant="h3" color="inherit" gutterBottom>{title}</Typography>
                  <Typography variant="h5" color="inherit" component={Markdown} source={description} gutterBottom />
                </div>
              </Grid>
              <Grid item md={6} lg={2} className={classes.headerContentSub}>
                <IconButton className={classes.iconButton} onClick={this.handleOpenClick} color="primary">
                  <LogoIcon className={classes.icon} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {showScrollButton && <Grid id="header-sub" className={classes.headerSub} item xs={12}>
            <IconButton color="primary" onClick={this.handleScrollClick}>
              <SvgIcon color="inherit" viewBox="0 0 512 512" aria-label="Down">
                <path d="M256,298.3L256,298.3L256,298.3l174.2-167.2c4.3-4.2,11.4-4.1,15.8,0.2l30.6,29.9c4.4,4.3,4.5,11.3,0.2,15.5L264.1,380.9c-2.2,2.2-5.2,3.2-8.1,3c-3,0.1-5.9-0.9-8.1-3L35.2,176.7c-4.3-4.2-4.2-11.2,0.2-15.5L66,131.3c4.4-4.3,11.5-4.4,15.8-0.2L256,298.3z" />
              </SvgIcon>
            </IconButton>
          </Grid>}
        </Grid>
      </div>
    )
  }
}

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  onScrollClick: PropTypes.object,
  headerData:  PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  })
}

export default withStyles(styles)(PageHeader)
