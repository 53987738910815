import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Fade from "@material-ui/core/Fade"
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button'

import Hero from './Hero'

const styles = theme => ({
  heroWrapper: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
});

class HeroWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loaded: this.props.loaded || false, open: false }
    this.handleScrollClick = this.handleScrollClick.bind(this)
    this.handleOpenClick = this.handleOpenClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleScrollClick(event) {
    event.preventDefault()
    // console.log(event)
    if (this.props.onScrollClick && this.props.onScrollClick.handler) {
      const { handler, id } = this.props.onScrollClick
      const scrollID = id || "hero-sub"
      handler(scrollID)
    }
  }

  handleOpenClick(event) {
    event.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  handleClose(event) {
    event.preventDefault()
    this.setState({
      open: false
    })
  }

  componentDidMount() {
    this.setState({
      loaded: true,
      open: false,
    })
  }

  render() {
    const { classes, data } = this.props
    return (
      <React.Fragment>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>Our New Logo</DialogTitle>
          <DialogContent>
            <DialogContentText>Glad you liked our logo enough to click on it. This was a re-branding of our logo here at ADARTA. The center "DART" is splitting the A's. <br /> Extra: You can find the "Dart" in some of our design throughout the site.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleClose}>
              OK
              </Button>
          </DialogActions>
        </Dialog>
        <Fade className={classes.heroWrapper} in={this.state.loaded} timeout={{ enter: 1000 }}>
          <Hero data={data} onOpenClick={this.handleOpenClick} onScrollClick={this.handleScrollClick}/>
        </Fade>
      </React.Fragment>
    )
  }
}

HeroWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  onScrollClick: PropTypes.object.isRequired
}

export default withStyles(styles)(HeroWrapper)
