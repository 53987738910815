import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import IconButton from "@material-ui/core/IconButton"
import Divider from "@material-ui/core/Divider"
import { withStyles } from "@material-ui/core/styles"
import Hidden from "@material-ui/core/Hidden"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },

});

class NavMenuDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
    if (typeof this.props.onMenuClose === 'function') {
      this.props.onMenuClose()
    }

  };

  render() {
    const { classes, items, theme } = this.props
    const { open } = this.state
    const openSaysMe = open || this.props.open

    return (
      <Hidden lgUp>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="right"
          open={openSaysMe}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {items && items.filter(item => !item.hidden).map((item, i) => {
              const itemComponent = (
                <ListItem button component={Link} to={`${item.path}`} key={item.label}>
                  <ListItemText primary={item.label} />
                </ListItem>
              )
              return itemComponent
            })}
          </List>
        </Drawer>
      </Hidden>
    )
  }
}

NavMenuDrawer.propTypes = {
  onOpen: PropTypes.func,
}

export default withStyles(styles, { withTheme: true })(NavMenuDrawer)
