import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Footer from "./Footer"

export default props => (
  <StaticQuery
    query={graphql`
      query {
        footer {
          copyright
          attributionName
          attributionURL
          cta {
            title
            sub_title
          }
        }
        attribution {
          people {
            name
            url
            intro
          }
        }
        social {
          types {
            name
            label
            url
            hidden
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
