import React from "react"
import { StaticQuery, graphql } from "gatsby"

import HeroWrapper from "./HeroWrapper"

export default props => (
  <StaticQuery
    query={graphql`
      query {
        header {
          title
          introduction
          learn_more
          learn_more_url
        }
      }
    `}
    render={data => <HeroWrapper data={data} {...props} />}
  />
)
