import React from 'react'
import PropTypes from 'prop-types'

import AttributionTag from './AttributionTag'

class Attribution extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
      {data.attribution && data.attribution.people && data.attribution.people.filter(i => !i.attribution_hidden).map((item, i) => {
        return (<AttributionTag key={i} data={item} />)
      })
      }
      </React.Fragment>
    )
  }
} 

Attribution.propTypes = {
  data: PropTypes.object,
}

export default Attribution
