import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

// import BurgerIcon from '../components/icons/BurgerIcon';
import LogoIcon from '../components/icons/LogoIcon';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 20,
    marginRight: -12,
  },
  homeButton: {
    margin: 0
  },
  icon: {
    width: 40,
    height: 40,
    "border-radius": 20,
    "& #top, #bottom": {
      fill: theme.palette.secondary.main
    },
    "& #dart": {
      fill: theme.palette.primary.main,
    },
    // "background-color": "white",
  },
  coName: {
    "font-size": "1.5rem",
    "padding-left": 10,
    "padding-right": 10,
    "white-space": "nowrap",
  },
});

class NavigationBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: props.open
    }
    this.handleOpenMenu = this.handleOpenMenu.bind(this)
  }

  handleOpenMenu(event) {
    event.preventDefault()
    const openMenu = !this.props.open
    this.setState({
      menuOpen: openMenu
    })
    if (typeof this.props.onMenuOpen === 'function') {
      this.props.onMenuOpen(openMenu)
    }
  }

  render() {
    const { classes, items } = this.props
    return (
      <AppBar color="default" position="fixed">
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            <Button className={classes.homeButton} color="inherit" component={Link} to="/">
              <LogoIcon className={classes.icon} /><Typography className={classes.coName} color="inherit">A D A R T A</Typography><LogoIcon rotate={-120} className={classes.icon} />
            </Button>
          </Typography>
          <Hidden mdDown>
          {items && items.filter(item => !item.hidden).map((item, i) => {
            const itemComponent = (<Button key={i} to={`${item.path}`} component={Link} color="inherit">{`${item.label}`}</Button>)
            return itemComponent
          })}
          </Hidden>
          <Hidden lgUp>
          <IconButton className={classes.menuButton} onClick={this.handleOpenMenu} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    )
  }
}


NavigationBar.propTypes = {
  onOpen: PropTypes.func,
}

export default withStyles(styles)(NavigationBar)
