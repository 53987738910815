import React from 'react'
import PropTypes from 'prop-types'

import NavigationBar from './NavigationBar';
import NavMenuDrawer from './NavMenuDrawer';

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openMenu: false
    }
    this.handleOpenMenu = this.handleOpenMenu.bind(this)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)
  }

  handleOpenMenu(open) {
    this.setState({
      openMenu: open
    })
  }
  handleCloseMenu() {
    this.setState({
      openMenu: false
    })
  }

  render() {
    const { data } = this.props
    const items = data.navigation.menu.items
    const { openMenu } = this.state

    return (
      <React.Fragment>
        <NavigationBar items={items} open={openMenu} onMenuOpen={this.handleOpenMenu} />
        <NavMenuDrawer items={items} open={openMenu} onMenuClose={this.handleCloseMenu} />
      </React.Fragment>
    )
  }
}

Navigation.propTypes = {
  onOpen: PropTypes.func,
}

export default Navigation
