import React from 'react'
import PropTypes from 'prop-types'

import SocialListItem from './SocialListItem'

class SocialList extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        {data.social && data.social.types && data.social.types.filter(item => !item.hidden).map((item, i) => (
          <SocialListItem key={i} data={item} />
        ))}
      </React.Fragment>
    )
  }
} 

SocialList.propTypes = {
  data: PropTypes.object,
}

export default SocialList
